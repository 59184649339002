import { render, staticRenderFns } from "./UsoTelefonicoPage.vue?vue&type=template&id=067a58bb&scoped=true"
import script from "./UsoTelefonicoPage.vue?vue&type=script&lang=js"
export * from "./UsoTelefonicoPage.vue?vue&type=script&lang=js"
import style0 from "./UsoTelefonicoPage.vue?vue&type=style&index=0&id=067a58bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "067a58bb",
  null
  
)

export default component.exports